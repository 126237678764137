import React from "react"

import styles from "./styles.module.css";

const ContentPage = ({title,children, containerClassName='', isSmallFonts}) => (
  <>
    <div className={[styles.provacy_main_section,containerClassName,isSmallFonts ? styles.smallFonts : ''].join(' ')}>
        <div className={[styles.top_banner_section,'d-flex','justify-content-center'].join(' ')}>
            <div className={['align-self-end', 'w-100'].join(' ')}>
                <div className="align-self-stretch">
                    <div className="container">
                        <div className={["row", "justify-content-md-center"].join(" ")}>
                            <div className={[styles.mpr_main_title ,'col-md-10', 'mb-5', 'pb-5'].join(' ')}>
                                <h2>{title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.entry_content}>
            <div className="container">
                <div className={["row", "justify-content-md-center"].join(" ")}>
                    <div className="col-md-10">
                        {
                            children
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  </>
)

export default ContentPage
