import React from "react"
import Layout from "../components/flayout"
import RefundPolicy from "../components/refundPolicy"

const Page = () => (
  <Layout darkHeader={true} seoFields={seoFields} showAnnouncement={false}>
      <RefundPolicy />
  </Layout>
)

const seoFields = {
  description: "Read MProfit's refund policy.",
  // lang,
  // meta,
  // keywords,
  title: "Refund Policy" 
}

export default Page
