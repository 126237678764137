import React from "react"

import ContentPage from "../../components/contentPage"
import { Link } from "gatsby";

const RefundPolicy = () => (
  <>
    <ContentPage title={'MProfit Refund Policy'} isSmallFonts={true}>

			<p>
				MProfit provides every user with the option to avail a free trial, so that you can easily evaluate our products before you make a purchase decision. The trial period helps you evaluate MProfit products to make sure that you make an informed purchase decision.
			</p>
			<p>
				MProfit's software products are functional during the trial period. Upon the purchase of an MProfit subscription and after payment clearance, your license to use the software will be activated. After the activation of the license, you will not be entitled to any refunds.
			</p>
			<p>
				In rare instances and only within the first 30 days of purchase, if you are unable to use MProfit due to technical difficulties or platform incompatibilities, we may, at our discretion, issue a refund, subject to us ascertaining that you are facing a genuine problem in using MProfit.
			</p>
			<p>
				In such instances, we will require that you provide enough information for us to positively identify your purchase transaction (e.g., Invoice number, registered email, date of invoice).
			</p>
			<br></br>

			<h4>Acceptance of this Refund Policy:</h4>
			<p>
				It is your responsibility to familiarise yourself with this refund policy. By placing an order for any of our software products, you indicate that you have read this refund policy and that you agree with and fully accept the terms of this refund policy.
			</p>

    </ContentPage>
  </>
)


export default RefundPolicy
